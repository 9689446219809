body {
  margin: 0;
  padding: 0;
  font-family: monospace, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
